import { Link, Typography } from "@mui/material";


export default function Confirmation() {
    return (
        <>
            <Typography variant="h6" gutterBottom>
                Bestätigung
            </Typography>
            <Typography variant="body1" gutterBottom>
                Ihre Anmeldung ist bei uns eingegangen. Sie erhalten als Bestätigung eine Email.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Bei Fragen können Sie sich gern unter <Link href="mailto:events@zfh.uni-hannover.de">events@zfh.uni-hannover.de</Link> melden.
            </Typography>
        </>
    );
}
    