import { Link, Typography } from "@mui/material";

import { ReservationRequest } from '../../types/common';
import Form from "./Form";

interface FormProps {
    data: ReservationRequest
    setData: (data: ReservationRequest) => void;
}

export default function Review(props: FormProps) {
    return (
        <>
            <Typography variant="h6" gutterBottom>
                Überprüfen Sie bitte Ihre Angaben
            </Typography>

            <Form data={props.data} setData={props.setData} readonly={true} />

        </>
    );

}