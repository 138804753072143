import { Box, Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';

import Cookies from 'universal-cookie';

export default function Header() {
  const cookies = new Cookies();
  const [token, setToken] = React.useState<string | undefined>(cookies.get('token'));
  const [isLoggedIn, setLoggedIn] = React.useState<boolean>(token !== undefined);

  function handleLogoutButton() {
    cookies.remove('token', { path: '/' });
    setToken(undefined);
    setLoggedIn(false);
    window.location.href = "/";
  }


  return (
    <AppBar
      position="absolute"
      color="default"
      elevation={0}
      sx={{
        position: 'relative',
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          Online-Anmeldeformular Uni-Sommerfest
        </Typography>
        {isLoggedIn &&
          <Box sx={{
            marginLeft: "auto"
          }} >
            <Button href='/list' color="inherit" >
              Reservierungen
            </Button>
            <Button color="inherit"
              onClick={handleLogoutButton}
            >
              Logout
            </Button>
          </Box>
        }
        {!isLoggedIn && <Button sx={{
          marginLeft: "auto"
        }} color="inherit" href='/login' >Login</Button>
        }
      </Toolbar>
    </AppBar>
  );
}