import { Link, Typography } from "@mui/material";

export default function InfoText() {
    return (
        <>
            <Typography gutterBottom>
                Bitte melden Sie Ihren Stand für das Uni-Sommerfest bis zum 01. Juni an. Mit einem * gekennzeichnete Felder sind Pflichtfelder. Bei Fragen melden Sie sich gern unter <Link href="mailto:events@zfh.uni-hannover.de">events@zfh.uni-hannover.de</Link>.
            </Typography>
        </>
    );
}
