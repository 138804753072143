import { FormLabel, TextField, Typography } from "@mui/material";
import { useEffect } from "react";

import { ReservationRequest } from '../../types/common';

interface FormProps {
    data: ReservationRequest;
    setData: (data: ReservationRequest) => void;
    readonly?: boolean;
}

const MaterialTypes: Array<{ name: string, label: string }> = [
    {
        name: "stuehle", label: "Stühle"
    },
    {
        name: "tische", label: "Tische"
    },
    {
        name: "stellwaende", label: "Stellwände"
    },
    {
        name: "stehtische", label: "Stehtische"
    },
    {
        name: "tresen", label: "Tresen"
    },
    {
        name: "biergarnitur", label: "Bierzeltgarnituren (1 Tisch + 2 Bänke)"
    },
    {
        name: "kuehltruhen", label: "Kühltruhen"
    },
    {
        name: "becher", label: "0,4 l Becher (1 € Pfand pro Becher)"
    },
]

export default function MaterialInput(props: FormProps) {
    const { data, setData, readonly } = props;

    const allNames = MaterialTypes.map((material) => material.name);

    const updateData = (materialName: string, value: number) => {
        let newData = { ...data };
        newData.materials[materialName] = value;
        setData(newData);
    }

    const validate = (event: React.ChangeEvent<HTMLInputElement>) => {
        let name = event.target.name;
        console.log(name);
        let value = parseInt(event.target.value);

        if (isNaN(value)) {
            value = 0;
        }

        value = Math.max(0, value);

        if (name === "becher") {
            value = Math.min(1000, value);
        } else {
            value = Math.min(10, value);
        }


        updateData(name, value);
    }

    useEffect(() => {
        let newData = { ...data };
        allNames.forEach((name) => {
            if (!(name in newData.materials)) {
                newData.materials[name] = 0;
            }
        });
        setData(newData);
    }, []);

    return (
        <>
            <FormLabel sx={{ pb: 5 }}>Benötigte Materialien</FormLabel>
            {Object.keys(data.materials).map((name) => {
                const material = MaterialTypes.find((material) => material.name === name);

                if (!material) {
                    return null;
                }

                let stepSize = 1;

                if (name === "becher")
                    stepSize = 50;

                return (
                    <TextField
                        id="standard-number"
                        label={material.label}
                        key={material.name}
                        name={material.name}
                        type="number"
                        value={data.materials[material.name]}
                        inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: props.readonly, step: stepSize }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant={props.readonly ? "filled" : "outlined"}
                        fullWidth
                        onChange={validate}
                        sx={{ mt: 2 }}
                    />
                )
            })
            }
        </>
    );
}
