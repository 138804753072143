import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import RegistrationForm from './pages/Registration';
import RegistrationList from './pages/RegistrationList';
import Login from './pages/Login';


import Header from './components/Header';

function App() {
  return (
    <>
      <CssBaseline />
      <Header />
      <Routes>
        <Route path="/" element={<RegistrationForm />} />
        <Route path="/list" element={<RegistrationList />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>

  );
}

export default App;
