import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function DeleteConfirmDialog(props: { open: boolean, onConfirm: () => void, onCancel: () => void }) {

    const { open, onConfirm, onCancel } = props;

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Wirklich löschen?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Möchten Sie Alle Einträge wirklich löschen?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Abbrechen</Button>
                <Button onClick={onConfirm} color="error">
                    Löschen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
