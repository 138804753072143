import * as React from 'react';
import { ReservationResponse } from '../types/common';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Stack, Typography } from '@mui/material';
import DeleteConfirmDialog from '../components/List/DeleteDialog';
import Cookies from 'universal-cookie';

const PATH = '/api/v1/reservation/list';
const PATH_DOWNLOAD = '/api/v1/reservation/export';

const columns: GridColDef[] = [
    {
        field: 'createdAt',
        headerName: 'Erstellt am',
        width: 200,
        editable: false,
        valueFormatter: params => new Date(params.value as number).toLocaleString(),
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 250,
        editable: false,
    },
    {
        field: 'institution',
        headerName: 'Institution',
        width: 250,
        editable: false,
    },
    {
        field: 'email',
        headerName: 'E-Mail',
        width: 250,
        editable: false,
    },
    {
        field: 'phone',
        headerName: 'Telefon',
        width: 250,
        editable: false,
    }
];

export default function RegistrationList() {
    const cookies = new Cookies();
    const [registrations, setRegistrations] = React.useState<ReservationResponse[]>([]);
    const [loading, setLoading] = React.useState(true);

    const [error, setError] = React.useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const handleDelete = () => {

        fetch(PATH, {
            method: 'DELETE',
        })
            .then((response) => response.json())
            .then((data) => {
                setRegistrations(data);
                setLoading(false);
                console.log(data);
            })
            .catch((error) => {
                setError(error);
            }
            );
    }

    const handleDownload = async () => {
        let request = await fetch(PATH_DOWNLOAD);
        let blob = await request.blob();
        let url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    React.useEffect(() => {
        fetch(PATH)
            .then((response) => response.json())
            .then((data) => {
                setRegistrations(data);
                setLoading(false);
                console.log(data);
            })
            .catch((error) => {
                setError(error);
            }
            );
    }, []);

    if (!cookies.get('token'))
        window.location.href = "/login";

    if (loading) {
        return <Typography variant="h4" align="center">Lade...</Typography>
    }

    return (<>
        <DeleteConfirmDialog open={openDeleteDialog} onConfirm={() => { handleDelete(); setOpenDeleteDialog(false) }} onCancel={() => setOpenDeleteDialog(false)} />
        <DataGrid rows={registrations} columns={columns} getRowId={(row) => row._id} autoHeight />
        <Stack spacing={2} direction="row-reverse" margin={2}>
            <Button variant="contained" onClick={handleDownload}>Herunterladen</Button>
            <Button variant="contained" color="error" onClick={() => setOpenDeleteDialog(true)}>Alle Löschen</Button>
        </Stack>
    </>)
}
