import { Divider, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup, TextField, Radio } from "@mui/material";
import React from "react";
import MaterialInput from "./MaterialInput";

import { ReservationRequest } from '../../types/common';


interface FormProps {
    data: ReservationRequest;
    setData: (data: ReservationRequest) => void;
    readonly?: boolean;
    triedToSubmit?: boolean;
}

export default function Form(props: FormProps) {
    const { data, setData } = props;


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(event.target.name, event.target.value);
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const variant = props.readonly ? "filled" : "outlined";

    return (
        <React.Fragment>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={props.triedToSubmit && data.name === ""}
                        id="name"
                        name="name"
                        label="Vor- und Nachname"
                        fullWidth
                        autoComplete="name"
                        variant={variant}
                        value={data.name}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={props.triedToSubmit && data.institution === ""}
                        id="institution"
                        name="institution"
                        label="Institut / Einrichtung / Fachschaft"
                        fullWidth
                        variant={variant}
                        value={data.institution}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={props.triedToSubmit && data.email === ""}
                        id="email"
                        name="email"
                        label="E-Mail Adresse"
                        fullWidth
                        autoComplete="email"
                        variant={variant}
                        value={data.email}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={props.triedToSubmit && data.phone === ""}
                        id="phone"
                        name="phone"
                        label="Telefonnummer"
                        fullWidth
                        autoComplete="phone"
                        variant={variant}
                        value={data.phone}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={props.triedToSubmit && data.requiredSpace === ""}
                        id="requiredSpace"
                        name="requiredSpace"
                        label="Wieviel Platz benötigt Ihr Stand? (mxm)"
                        fullWidth
                        variant={variant}
                        value={data.requiredSpace}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={props.triedToSubmit && data.offering === ""}
                        id="offering"
                        name="offering"
                        label="Was möchten Sie anbieten, präsentieren oder verkaufen?"
                        fullWidth
                        multiline
                        rows={4}
                        variant={variant}
                        value={data.offering}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="devices"
                        name="devices"
                        label="Welche elektrischen Geräte betreiben Sie an Ihrem Stand?"
                        fullWidth
                        multiline
                        rows={4}
                        variant={variant}
                        value={data.devices}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={props.triedToSubmit && data.electricity === ""}
                        id="electricity"
                        name="electricity"
                        label="Wie viel Strom (in kW) benötigen Sie?"
                        fullWidth
                        variant={variant}
                        value={data.electricity}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MaterialInput data={data} setData={setData} readonly={props.readonly} />
                </Grid>

                <Grid item xs={12}>
                    <FormControl disabled={props.readonly} error={props.triedToSubmit && data.removalTime === ""}>
                        <FormLabel>Wann möchten Sie (ungefähr) abbauen? *</FormLabel>
                        <RadioGroup row aria-label="abbau" name="removalTime" value={data.removalTime} onChange={handleChange}
                        >
                            <FormControlLabel value="20:00" control={<Radio />} label="20:00" />
                            <FormControlLabel value="20:30" control={<Radio />} label="20:30" />
                            <FormControlLabel value="21:00" control={<Radio />} label="21:00" />
                            <FormControlLabel value="21:30" control={<Radio />} label="21:30" />
                            <FormControlLabel value="22:00" control={<Radio />} label="22:00" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="recommendations"
                        name="recommendations"
                        label="Haben Sie noch Empfehlungen zum Bühnenprogramm?"
                        fullWidth
                        multiline
                        rows={4}
                        variant={variant}
                        value={data.recommendations}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="comments"
                        name="comments"
                        label="Platz für Anmerkungen oder weitere Anfragen"
                        fullWidth
                        multiline
                        rows={4}
                        variant={variant}
                        value={data.comments}
                        onChange={handleChange}
                        inputProps={{ readOnly: props.readonly }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>

    );
}