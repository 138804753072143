import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';

import Form from '../components/Registration/Form';


// image import
import logo from '../resources/banner2.jpg';
import Confirmation from '../components/Registration/Confirmation';

import { ReservationRequest } from '../types/common';
import Review from '../components/Registration/Review';
import InfoText from '../components/Registration/InfoText';
import { Alert, Backdrop, CircularProgress } from '@mui/material';

const steps = ['Anmeldeinformationen', 'Überprüfen'];

function getStepContent(step: number, formData: ReservationRequest, setFormData: (data: ReservationRequest) => void, triedToSubmit: boolean) {
  switch (step) {
    case 0:
      return <Form data={formData} setData={setFormData} triedToSubmit={triedToSubmit} />;
    case 1:
      return <Review data={formData} setData={setFormData} />;
    default:
      throw new Error('Unknown step');
  }
}

const defaultFormData: ReservationRequest = {
  name: '',
  institution: '',
  email: '',
  phone: "",
  requiredSpace: "",
  offering: "",
  devices: "",
  removalTime: "",
  electricity: "",
  materials: {},
  recommendations: "",
  comments: "",
}

const requiredFields = ["name", "institution", "email", "phone", "requiredSpace", "offering", "removalTime", "electricity"]

export default function RegistrationForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [triedToSubmit, setTriedToSubmit] = React.useState(false);
  const [formData, setFormData] = React.useState<ReservationRequest>(defaultFormData);

  const [error, setError] = React.useState("");

  const checkRequired = (): Boolean => {
    let valid = true;
    requiredFields.forEach((key) => {
      if (formData[key as keyof ReservationRequest] === "") {
        console.log("invalid: " + key)
        valid = false;
      }
    })
    return valid;
  }

  const handleSubmit = () => {
    setLoading(true);
    setError("");
    console.log("send request");
    console.log(formData);

    fetch("/api/v1/reservation/new", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      if (response.ok) {
        setActiveStep(activeStep + 1);
      } else {
        setError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
      }
    }).finally(() => {
      setLoading(false);
    }
    )
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    setError("");
    setTriedToSubmit(true)
    if (!checkRequired()) {
      setError("Bitte füllen Sie alle Pflichtfelder aus.");
      return;
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep(activeStep - 1);
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {activeStep === 0 && (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: 0 }}>
          <img src={logo} alt="logo" style={{
            height: "300px",
            width: "100%",
            objectFit: "cover",
            padding: "20px"
          }} />
          <Container sx={{ marginBottom: "20px" }}>
            <InfoText />
          </Container>

        </Paper>)}

      <Stepper activeStep={activeStep} sx={{ pt: 2, pb: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        {error !== "" && (
          <Alert sx={{ mb: 5 }} severity='error'>{error}</Alert>
        )}
        {activeStep === steps.length ? (
          <Confirmation />
        ) : (
          <React.Fragment>
            {getStepContent(activeStep, formData, setFormData, triedToSubmit)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Zurück
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Weiter
                </Button>
              )
              }
              {activeStep == steps.length - 1 && (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Reservierung abschicken
                </Button>
              )
              }
            </Box>
          </React.Fragment>
        )}
      </Paper>

      <Backdrop
        sx={{ color: '#fff' }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}