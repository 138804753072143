import { Alert, Button, Container, Paper, TextField, Typography } from '@mui/material'
import React from 'react';
import Cookies from 'universal-cookie';

export default function Login() {
    const cookies = new Cookies();
    const [error, setError] = React.useState("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console

        const url_login = '/api/v1/auth/login';

        const request = await fetch(url_login, {
            method: 'POST',
            body: JSON.stringify({
                email: data.get('email'),
                password: data.get('password'),
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.token) {
                    cookies.set('token', data.token, { path: '/' });
                    window.location.href = "/list";
                    return;
                }

                if (data.message)
                    setError(data.message);
                else
                    setError("Login failed");

            })


            .catch((error) => {
                console.error('Error:', error);
            });

    };


    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: 5 }}>
                <Typography variant="h4" component="h4" align="center" sx={{ my: 2 }}>
                    Login
                </Typography>
                {error && <Alert sx={{ mb: 5 }} severity='error'>{error}</Alert>}
                <form onSubmit={handleSubmit}>
                    <TextField name="email" label="E-Mail" fullWidth variant='outlined' sx={{ my: 2 }} inputProps={{ readOnly: true }} value={"admin"} />
                    <TextField name="password" label="Password" type="password" fullWidth sx={{ my: 3 }} />

                    <Button variant="contained" type='submit' sx={{ my: 2 }} fullWidth>
                        Login
                    </Button>
                </form>
            </Paper>
        </Container>

    )
}